import { TextButtonVariants } from '@/components/atoms/TextButton/TextButton';
import TextButtonLink from '@/components/atoms/TextButtonLink/TextButtonLink';
import Carousel from '@/components/molecules/Carousel';
import { useZipContext } from '@/context/ZipContext';
import useWindowSize from '@/hooks/useWindowSize';
import { parseListingVehicle } from '@/lib/parseListingVehicle';
import {
  RawVehicleListingType,
  VehicleListingType,
} from '@/lib/schema/inventory/types';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { FC, useEffect, useMemo, useState } from 'react';
import { ProductListingLink } from '../ProductListingLink/ProductListingLink';

export const PopularMakesCarousel: FC = () => {
  const { userAddress, customZip } = useZipContext();

  const [vehicleList, setVehicleList] = useState<VehicleListingType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      let zip;
      if (customZip) {
        zip = customZip;
      } else if (userAddress && userAddress.country === 'US') {
        zip = userAddress.postal;
      }

      try {
        const response = await fetch('/api/inventory/listings/popular', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            postal: zip,
            limit: 20,
            radius_km: 322,
            sort: 'rankedscore_desc',
          }),
        });

        if (response.ok) {
          const data = await response.json();
          const { content: matchesRaw }: { content: RawVehicleListingType[] } =
            data;
          const matches = matchesRaw.map((product: RawVehicleListingType) =>
            parseListingVehicle(product)
          );

          setVehicleList(matches);
        }
      } catch (error) {
        setVehicleList([]);
      }
    };
    fetchData();
  }, [userAddress, customZip]);

  const { isDesktop, isML, isM, isMobile } = useWindowSize();
  const { imagesLength, buttonText } = useMemo(() => {
    if (isDesktop) {
      return {
        imagesLength: vehicleList.slice(0, 5).length,
        buttonText: 'View all',
      };
    }
    if (isML) {
      return {
        imagesLength: Math.ceil(vehicleList.length / 3),
        buttonText: 'View all',
      };
    }
    if (isM) {
      return {
        imagesLength: Math.ceil(vehicleList.length / 2),
        buttonText: 'View all',
      };
    }
    return { imagesLength: vehicleList.length, buttonText: '' };
  }, [vehicleList, isDesktop, isML, isM]);

  if (vehicleList.length > 0) {
    return (
      <div className="flex flex-col pt-[40px]">
        <div className="mb-xl flex items-center justify-between">
          <div className="text-h2Regular text-neutral-900">
            Shop by Popularity
          </div>
          <div>
            <TextButtonLink
              aria-label="View More Evs"
              variant={TextButtonVariants.Default}
              href="/search"
              icon={{
                position: 'right',
                icon: <ChevronRightIcon className="h-xl w-xl" />,
              }}
            >
              {buttonText}
            </TextButtonLink>
          </div>
        </div>
        <div>
          <Carousel
            className="w-full gap-l"
            showDirectionButtons={true}
            navigationPosition="bottom"
            imagesLength={imagesLength}
            infinite={true}
            isShowingDots={true}
          >
            {vehicleList.map((vehicle) => {
              return (
                <div
                  key={`popular-make-carousel-${vehicle.listingId}`}
                  className={`w-full pr-m m:w-1/2 ml:w-1/3 l:w-[25%]`}
                >
                  <ProductListingLink
                    showNewCheckAvailabilityButton={true}
                    product={vehicle}
                    isSaved={false}
                    isColumnLayout={true}
                    isListedPrice={true}
                    isMobile={isMobile}
                    contactedDealer={false}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
  return null;
};
