import Image from '@/components/atoms/Image';
import Button, { ButtonVariants } from '@/components/atoms/legacy/Button';
import Icon from '@/components/atoms/legacy/Icon';
import Link from '@/components/atoms/Link';
import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { getDefaultImage } from '@/components/organisms/ChooseTrimForm';
import {
  DEFAULT_US_ZIP_CODE,
  GOOGLE_TRACK_INFO,
  LOCATION_KEY,
} from '@/lib/constants';
import { priceFormatter } from '@/lib/priceFormatter';
import { TrimStyle } from '@/lib/schema/build/types';
import { useRouter } from 'next/router';
import { FC } from 'react';

interface ModelAndTrimInfoProps {
  year: string;
  make: string;
  model: string;
  trim: string;
}

const ModelAndTrimInfo: React.FC<ModelAndTrimInfoProps> = ({
  year,
  make,
  model,
  trim,
}) => {
  return (
    <div>
      <div className="text-[16px] font-medium leading-[22px] text-neutralsGrey-700">
        {year} {make} {model}
      </div>
      <div className="text-[16px] font-light leading-[22px] text-neutralsGrey-700">
        {trim}
      </div>
    </div>
  );
};

interface TaxCreditProps {
  msrp: number;
  credit: number;
}

const TaxCredit: React.FC<TaxCreditProps> = ({ msrp, credit }) => {
  return (
    <div className="flex justify-between">
      <div className="text-[14px] font-medium leading-[20px] text-neutralsGrey-500">
        MSRP <s>{priceFormatter(msrp)}</s>
      </div>
      <div className="flex items-center gap-[4px]">
        <div className="bg-lightGreen px-[4px] py-[2px] text-[10px] text-darkGreen">
          {priceFormatter(credit)} tax credit
        </div>
        <Icon strokeColor="#1F64CB" iconId="Information2" />
      </div>
    </div>
  );
};

const Separator: React.FC = () => {
  return <div className="mb-[4px] mt-[3px] h-[1px] bg-neutralsGrey-400" />;
};

interface CustomVehicleCardProps {
  vehicleDetails: TrimStyle;
  taxCredit?: number;
}

export const CustomVehicleCard: FC<CustomVehicleCardProps> = ({
  vehicleDetails,
}) => {
  const router = useRouter();
  const { handleActionTracking } = useAnalytics();
  const { year, make, model, msrp, exteriorImages, trim } = vehicleDetails;

  const defaultImage = getDefaultImage(exteriorImages);

  const forwardToChooseTrimPage = () => {
    const location = localStorage.getItem(LOCATION_KEY) || DEFAULT_US_ZIP_CODE;
    handleActionTracking({
      ...GOOGLE_TRACK_INFO.customOrderHomePageButton,
      pageTarget: `/build/${location}/${make}/${year}/${model}`,
    });

    router.push({
      pathname: '/build/[location]/[make]/[year]/[model]',
      query: {
        location,
        make,
        model,
        year,
      },
    });
  };

  const modelUrl = {
    pathname: '/model/[make]/[year]/[model]',
    query: {
      make,
      year,
      model,
    },
  };
  const forwardToModelPage = () => {
    handleActionTracking({
      ...GOOGLE_TRACK_INFO.forwardToModelPageImageButton,
      pageTarget: `/model/${make}/${year}/${model}`,
    });
    router.push(modelUrl);
  };

  return (
    <div className="flex h-[432px] flex-col">
      <div role="button" onClick={forwardToModelPage} className="flex flex-col">
        <div className="flex h-[190px] justify-center">
          <Image
            src={defaultImage?.images?.[0]?.href}
            width={280}
            height={190}
            className="object-cover"
            alt={`${year}-${make}-${model}`}
          />
        </div>
        <div className="flex justify-between p-[16px] pb-[8px]">
          <ModelAndTrimInfo year={year} make={make} model={model} trim={trim} />
          <Link
            className="inline-block px-[8px] py-[4px] text-[14px] font-normal text-content-info"
            href={modelUrl}
          >
            View details
          </Link>
        </div>
      </div>
      <div className="px-[16px]">
        <Separator />
      </div>
      <div
        role="button"
        onClick={forwardToChooseTrimPage}
        className="flex grow flex-col justify-between px-[24px] pb-[16px] pt-[8px]"
      >
        <div>
          {vehicleDetails.incentivesTotal != null &&
          vehicleDetails.incentivesTotal > 0 ? (
            <>
              <TaxCredit msrp={msrp} credit={vehicleDetails.incentivesTotal} />
              <div className="text-[20px] leading-[26px]">
                {priceFormatter(msrp - vehicleDetails.incentivesTotal)}
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-between">
                <div className="text-[14px] font-medium leading-[20px] text-neutralsGrey-500">
                  MSRP
                </div>
              </div>
              <div className="text-[20px] leading-[26px]">{`$${msrp.toLocaleString()}`}</div>
            </>
          )}
        </div>
        <Button
          variant={ButtonVariants.Secondary}
          className="w-full rounded-[4px]"
        >
          Custom order
        </Button>
      </div>
    </div>
  );
};
