import { ResponsiveMargin } from '@/components/atoms/legacy/ResponsiveMargin/ResponsiveMargin';
import useWindowSize from '@/hooks/useWindowSize';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { FC, useEffect, useMemo, useState } from 'react';
import { HomePageMakeModelsBar } from '../HomePageMakeModelsBar/HomePageMakeModelsBar';
import { HomePageSearchBar } from '../HomePageSeachBar/HomePageSearchBar';

const DynamicVideo = dynamic(() => import('@/components/atoms/Video/Video'), {
  loading: () => (
    <div className="relative h-full w-full">
      <Image
        className="absolute z-0 h-full w-full object-cover"
        src={
          'https://homepage-hero-videos.s3.amazonaws.com/hero-poster-desktop.png'
        }
        alt="Hero Video Poster"
        layout="fill"
        objectFit="cover"
      />
    </div>
  ),
  ssr: false,
});

export type HeroSectionProps = {
  makes: string[];
  models: { [key: string]: string[] };
};
export const NewHeroSection: FC<HeroSectionProps> = (props) => {
  const { isDesktop, isTablet } = useWindowSize();
  const [showButtonContainer, setShowButtonContainer] = useState(false);

  const { src, posterSrc } = useMemo(() => {
    let screenSize = 'mobile';
    if (isDesktop) {
      screenSize = 'desktop';
    } else if (isTablet) {
      screenSize = 'tablet';
    }
    return {
      src: `https://homepage-hero-videos.s3.amazonaws.com/hero-video-${screenSize}.mp4`,
      posterSrc: `https://homepage-hero-videos.s3.amazonaws.com/hero-poster-${screenSize}.png`,
    };
  }, [isDesktop, isTablet]);

  useEffect(() => {
    if (!showButtonContainer && isDesktop) {
      setShowButtonContainer(true);
    }
  }, [isDesktop, showButtonContainer]);

  return (
    <>
      <div className="relative w-full">
        <div className="absolute z-0 h-full w-full">
          <DynamicVideo
            src={src}
            posterSrc={posterSrc}
            label="home page background video"
            alt="home page background poster"
          />
        </div>

        <ResponsiveMargin>
          <div
            className={`relative flex h-[100vh] w-full flex-col items-center justify-center ${showButtonContainer || !isDesktop ? 'opacity-100' : 'opacity-0'} transition-opacity`}
          >
            <h1 className="mb-4 text-center text-h1SemiBold text-neutral-0 m:text-homepageH1SemiBold">
              {SEOMetaTags.homePage.h1Tag}
            </h1>
            <h2 className="mb-8 text-center text-h3Regular font-normal text-neutral-0">
              {SEOMetaTags.homePage.h2Tag}
            </h2>
            <div className="flex w-full flex-col m:w-[60%]">
              {process.env.NEXT_PUBLIC_SHOW_SEARCH_BAR === 'true' ? (
                <HomePageSearchBar makes={props.makes} models={props.models} />
              ) : (
                <HomePageMakeModelsBar
                  makes={props.makes}
                  models={props.models}
                />
              )}
            </div>
          </div>
        </ResponsiveMargin>
      </div>
    </>
  );
};
